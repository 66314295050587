import { Avatar, Box, DropButton } from "grommet"
import { User } from "grommet-icons"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { useUser } from "../hooks"
import ProfileMenu from "./profile-menu"
import { getTestIdAttribute, header, userProfile } from "../utils/test-ids"

const StyledAvatar = styled(Avatar)`
  :focus-within,
  :hover {
    border-color: #2ad2c9;
    border-width: 2px;
  }
`

export default function UserAvatar() {
  const user = useUser()
  const [name, setName] = useState(null)

  useEffect(() => {
    if (user) {
      setName(`${user.profile.given_name} ${user.profile.family_name}`)
    }
  }, [user])

  return (
    <StyledAvatar
      border={{ side: "all", color: "text-strong" }}
      {...getTestIdAttribute(header.avatar)}
    >
      <DropButton
        dropAlign={{ top: "bottom", right: "right" }}
        dropContent={<ProfileMenu name={name} />}
        fill
        hoverIndicator={false}
        icon={
          <Box align="center">
            <User color="text-strong" size="large" />
          </Box>
        }
        plain
        {...getTestIdAttribute(userProfile.picture)}
      />
    </StyledAvatar>
  )
}

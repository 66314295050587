import { Box, Button } from "grommet"
import { Notification } from "grommet-icons"
import { lazy, Suspense } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useTranslation } from "react-i18next"
import { useAuthZ } from "../hooks"
import { getTestIdAttribute, header } from "../utils/test-ids"

const NotificationsButtonPlugin = lazy(
  () => import("./notifications-button-plugin")
)

export default function NotificationsButton() {
  const { permissions } = useAuthZ()
  const { t } = useTranslation()

  return (
    <ErrorBoundary
      fallbackRender={() => null}
      onError={(error) => console.error(error)}
    >
      <Suspense fallback={null}>
        <NotificationsButtonPlugin>
          <Button
            disabled={!permissions.includes("issue.read")}
            slot="target"
            icon={<Notification size="large" />}
            tip={{
              content: (
                <Box {...getTestIdAttribute(header.notifications.tip)}>
                  {t("tipNotifications")}
                </Box>
              ),
            }}
            {...getTestIdAttribute(header.notifications.icon)}
          />
        </NotificationsButtonPlugin>
      </Suspense>
    </ErrorBoundary>
  )
}

import { env, isONPREM } from "./env"

const ldOptions = {
  diagnosticOptOut: true,
  fetchGoals: false,
  privateAttributeNames: ["email"],
  // sendEvents is required for user registration and debugging
  sendEvents: true,
  sendLDHeaders: false,
  streaming: env.REACT_APP_LD_MOCK === "true" ? false : true,
}

const onPremFlags = {
  "shell-enable-analytics-1": false,
  "shell-enable-announcements-1": true,
  "shell-enable-audit-1": true,
  "shell-enable-email-notifications-1": true,
  "shell-enable-file-storage-1": true,
  "shell-enable-groups-1": true,
  "shell-enable-notifications-1": false,
  "shell-enable-objects-1": true,
  "shell-enable-rtm-1": true,
  "shell-enable-search-1": true,
  "shell-enable-secrets-1": true,
  "shell-enable-sfm-1": true,
  "shell-enable-shapeshifter-1": false,
  "shell-enable-zerto-1": true,
  "dscc-data-observability": true,
}

const cloudFlags = {
  "shell-enable-analytics-1": false,
  "shell-enable-announcements-1": false,
  "shell-enable-audit-1": false,
  "shell-enable-email-notifications-1": false,
  "shell-enable-file-storage-1": false,
  "shell-enable-groups-1": false,
  "shell-enable-notifications-1": false,
  "shell-enable-objects-1": false,
  "shell-enable-rtm-1": false,
  "shell-enable-search-1": false,
  "shell-enable-secrets-1": false,
  "shell-enable-sfm-1": false,
  "shell-enable-shapeshifter-1": false,
  "shell-enable-zerto-1": false,
  "dscc-data-observability": false,
  "dscc-webhooks-service": false,
  "ezmeral-kubernetes-service": false,
}

// Map of flag to default value
const ldFlags = isONPREM() ? onPremFlags : cloudFlags

export const LDConfig = {
  clientSideID: env.REACT_APP_LD_CLIENTID,
  flags: ldFlags,
  options: ldOptions,
  reactOptions: { useCamelCaseFlagKeys: false },
}

import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import AuthService from "../oauth/auth-service"
import { APP_INSTANCE_ID, CCS_LOGIN_URL } from "../oauth"

export function Login() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    // Login Scenarios
    // +-------+-------+-----------------------------------+
    // |  cid  | user  |              action               |
    // +-------+-------+-----------------------------------+
    // | true  | true  | begin OAuth flow for new tokens   |
    // | true  | false | begin OAuth flow for new tokens   |
    // | false | true  | redirect home                     |
    // | false | false | redirect to CCS for initial login |
    // +-------+-------+-----------------------------------+
    async function login() {
      // we have cid search param, begin OAuth flow whether user already signed in or not
      // could be a new cid that requires new tokens even if user already signed in
      const cid = searchParams.get("cid")
      if (cid) {
        return AuthService.login({ cid })
      }

      // no cid param and user already logged in, redirect home
      const user = await AuthService.getUser()
      if (user) {
        return navigate("/", { replace: true })
      }

      // no cid and no user
      // redirect to CCS for customer login
      window.location.replace(`${CCS_LOGIN_URL}?app=${APP_INSTANCE_ID}`)
    }

    login()
  }, [navigate, searchParams])

  return null
}

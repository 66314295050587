import { Box, Button, Card, Text } from "grommet"
import { getTestIdAttribute } from "../../utils/test-ids"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

const BodyText = styled(Text)`
  white-space: pre-line;
`

export function ServiceItem(content) {
  const { t } = useTranslation()

  return (
    <Card
      align="start"
      elevation="small"
      gap="medium"
      pad="medium"
      {...getTestIdAttribute(content.additionalServicesTestId)}
    >
      <Box gap="medium" direction="row" align="center">
        <Box
          align="center"
          background={content.color || "unset"}
          width="80px"
          height="80px"
          pad="30px"
          round="40px"
        >
          {content.icon("large")}
        </Box>
        <Text
          color="#444"
          size="xx-large"
          weight={500}
          {...getTestIdAttribute(`${content.additionalServicesTestId}-title`)}
        >
          {t(content.learnMoreTitle)}
        </Text>
      </Box>
      <BodyText color="#444" size="small" textAlign="start">
        {t("learnMoreAtlasBody")}
      </BodyText>
      <Button
        onClick={(event) => {
          if (content.type !== "external") {
            event.preventDefault()
            content.setModalData({ content })
            content.setOpen(true)
          }
        }}
        rel="noopener noreferrer"
        label={
          <Box pad={{ vertical: "1px" }}>
            <Text
              size="19px"
              {...getTestIdAttribute(
                `${content.additionalServicesTestId}-learn-more`
              )}
            >
              {t(content.learnMoreButtonLabel)}
            </Text>
          </Box>
        }
        primary
        type="button"
        margin={{ top: "8px" }}
      />
    </Card>
  )
}

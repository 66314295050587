import { Box, Button, Text } from "grommet"
import { ShareRounded } from "grommet-icons"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { getTestIdAttribute, miniLaunchpad } from "../utils/test-ids"

const MiniLaunchPadItem = ({ setOpen, ...content }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Button
      plain={true}
      href={content.destinationPath}
      hoverIndicator={"#F4F6F9"}
      target={content.type === "external" ? "_blank" : "_self"}
      rel="noopener noreferrer"
      onClick={(event) => {
        if (content.type !== "external") {
          event.preventDefault()
          navigate(content.destinationPath, { state: { title: content.title } })
        }
        setOpen(false)
      }}
      {...getTestIdAttribute(miniLaunchpad.item)}
    >
      <Box
        key={content.title}
        flex={false}
        align="center"
        direction="row"
        gap="small"
      >
        {content.icon && (
          <Box
            align="center"
            background={content.color || "#E2E6EA"}
            pad="10px"
            round="xsmall"
          >
            {content.icon("20px")}
          </Box>
        )}
        <Box direction="row" gap="6px" align="center">
          <Text size="16px" color="text-strong">
            {t(content.title)}
          </Text>
          {content.type === "external" && <ShareRounded size="16px" />}
        </Box>
      </Box>
    </Button>
  )
}

export default MiniLaunchPadItem

import {
  Aid,
  Announce,
  Cluster,
  Compliance,
  Cube,
  DocumentVerified,
  DriveCage,
  Folder,
  Group,
  Inherit,
  Install,
  Integration,
  MailOption,
  Overview,
  PieChart,
  Resources,
  Secure,
  Servers,
  Shield,
  Task,
  Technology,
  Validate,
} from "grommet-icons"
import { routes } from "../../shell-routes"
import { Products, TileType } from "../../utils/constants"
import { env } from "../../utils/env"
import {
  getTestIdAttribute,
  additionalServices,
  launchpad,
} from "../../utils/test-ids"

/**
 * @callback requiredLDFlagsFn
 * @param {Object.<string, any>} userLDFlags User's LaunchDarkly flags
 * @returns {boolean} User has required LD flags
 */

/**
 * @callback requiredSubscriptionsFn
 * @param {Object.<string, any>} userLDFlags User's LaunchDarkly flags
 * @param {Object.<string, boolean>} userSubscriptions User's allowed subscriptions
 * @returns {boolean} User has required subscriptions
 */

/**
 * A launchpad tile
 * @typedef {Object} LaunchpadTile
 * @property {string} [additionalServicesTestId]
 * @property {boolean} [alwaysShow]
 * @property {string} buttonLabel
 * @property {string} [color]
 * @property {Object.<string, string>} dataTestIds
 * @property {string|requiredLDFlagsFn} [flag]
 * @property {string} key
 * @property {function(string): any} icon
 * @property {string} [learnMoreButtonLabel]
 * @property {string} [learnMoreLink]
 * @property {string} [learnMoreTitle]
 * @property {string[]} [productTypes]
 * @property {string[]} [resources]
 * @property {string[]|requiredSubscriptionsFn} [subscriptions]
 * @property {TileType} tileType
 * @property {string} title
 * @property {string} to
 * @property {string} [toMore]
 * @property {string} type
 */

/**
 * Tiles for the launchpad
 * @type {LaunchpadTile[]}
 */
const items = [
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#626B7C",
    dataTestIds: launchpad.tiles.allowDenyManager,
    description: "descriptionAllowDenyManager",
    icon: (size = "large") => (
      <DocumentVerified
        color="white"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.allowDenyManager.icon)}
      />
    ),
    key: routes["allow-deny-manager"].key,
    productTypes: [Products.ROP],
    resources: ["allow-entries.read", "deny-entries.read", "deny-reasons.read"],
    tileType: TileType.SERVICE,
    title: "titleAllowDenyManager",
    to: routes["allow-deny-manager"].navPath,
    type: routes["allow-deny-manager"].type,
  },
  {
    additionalServicesTestId: additionalServices.atlas,
    alwaysShow: true,
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#7630EA",
    dataTestIds: launchpad.tiles.atlas,
    description: "descriptionAtlas",
    key: routes.atlas.key,
    icon: (size = "large") => (
      <Shield
        color="white"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.atlas.icon)}
      />
    ),
    learnMoreButtonLabel: "launchpadActionButtonLearnMore",
    learnMoreLink:
      "https://buy.hpe.com/us/en/storage/storage-cloud/storage-saas/data-services/hpe-backup-recovery-service/p/1014420488",
    learnMoreTitle: "learnMoreAtlasTitle",
    productTypes: [Products.DSCC],
    resources: [
      "app-data-management-dashboard.read",
      "app-data-management-job.read",
      "app-data-management-on-prem-engine.read",
      "app-data-management-template.read",
      "application-host.read",
      "application-server.read",
      "catalyst-gateway.read",
      "copy-pool.read",
      "csp-k8s-application-backup.read",
      "csp-rds-instance.read",
      "database-schedule-job.read",
      "database-snapshot.read",
      "database.read",
      "datastore.read",
      "mssql-database-protection-group.read",
      "mssql-database.read",
      "mssql-instance-snapshot.read",
      "mssql-instance.read",
      "protection-job.read",
      "virtual-machine-protection-group.read",
      "virtual-machine.read",
    ],
    subscriptions: ["VM_PROTECTION"],
    tileType: TileType.SERVICE,
    title: "titleAtlas",
    to: `${routes.atlas.navPath}/dashboard`,
    toMore: routes.additionalServices.navPath,
    type: routes.atlas.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#7630EA",
    dataTestIds: launchpad.tiles.atlasRop,
    description: "descriptionAtlasRop",
    key: routes.atlasRop.key,
    icon: (size = "large") => (
      <Shield
        color="white"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.atlasRop.icon)}
      />
    ),
    productTypes: [Products.ROP],
    resources: ["glbr-support.read"],
    tileType: TileType.SERVICE,
    title: "titleAtlasRop",
    to: routes.atlasRop.navPath,
    type: routes.atlasRop.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#002E6D",
    dataTestIds: launchpad.tiles.block,
    description: "descriptionBlock",
    icon: (size = "large") => (
      <Cube
        color="white"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.block.icon)}
      />
    ),
    key: routes.block.key,
    productTypes: [Products.DSCC, Products.ONPREM],
    resources: [
      "host-initiator.read",
      "host-initiator-group.read",
      "volume.read",
      "volume-set.read",
    ],
    tileType: TileType.SERVICE,
    title: "titleBlock",
    to: routes.block.navPath,
    type: routes.block.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#00ADEF",
    dataTestIds: launchpad.tiles.cloudPhysics,
    description: "descriptionCloudPhysics",
    icon: (size = "large") => (
      <PieChart
        color="white"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.cloudPhysics.icon)}
      />
    ),
    key: routes.cloudPhysics.key,
    productTypes: [Products.DSCC],
    tileType: TileType.SERVICE,
    title: "titleCloudPhysics",
    to: routes.cloudPhysics.navPath,
    type: routes.cloudPhysics.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#00ADEF",
    dataTestIds: launchpad.tiles.clusterStatus,
    description: "descriptionClusterStatus",
    icon: (size = "large") => (
      <PieChart
        color="white"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.clusterStatus.icon)}
      />
    ),
    key: routes["cluster-status"].key,
    productTypes: [Products.ROP],
    tileType: TileType.SERVICE,
    title: "titleClusterStatus",
    to: routes["cluster-status"].navPath,
    type: routes["cluster-status"].type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#CCAAFF",
    dataTestIds: launchpad.tiles.dataObservability,
    description: "descriptionDataObservability",
    flag: "dscc-data-observability",
    icon: (size = "large") => (
      <Overview
        color="white"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.dataObservability.icon)}
      />
    ),
    key: routes.dataObservability.key,
    productTypes: [Products.DSCC],
    resources: ["data-observability.read"],
    tileType: TileType.SERVICE,
    title: "titleDataObservability",
    to: routes.dataObservability.navPath,
    type: routes.dataObservability.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#FC6161",
    dataTestIds: launchpad.tiles.zerto,
    description: "descriptionZerto",
    flag: "shell-enable-zerto-1",
    icon: (size = "large") => (
      <Aid
        color="white"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.zerto.icon)}
      />
    ),
    key: routes.zerto.key,
    productTypes: [Products.DSCC],
    resources: [
      "virtual-continuous-protection-group.read",
      "virtual-continuous-protection-replica.read",
      "virtual-continuous-protection-recovery.read",
      "virtual-site.read",
    ],
    subscriptions: ["DISASTER_RECOVERY"],
    tileType: TileType.SERVICE,
    title: "titleZerto",
    to: routes.zerto.navPath,
    type: routes.zerto.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#2AD2C9",
    dataTestIds: launchpad.tiles.dom,
    description: "descriptionDom",
    icon: (size = "large") => (
      <Servers
        color="white"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.dom.icon)}
      />
    ),
    key: routes["dom-fleet"].key,
    productTypes: [Products.DSCC, Products.ONPREM],
    resources: [
      "host-initiator.read",
      "host-initiator-group.read",
      "storage-system.read",
      "controller.read",
      "port.read",
      "shelf.read",
      "disk.read",
      "storage-pool.read",
      "volume.read",
      "restore-point.read",
      "sds-system.read",
      "system-settings.read",
      "volume-set.read",
    ],
    tileType: TileType.SERVICE,
    title: "titleDom",
    to: `${routes["dom-fleet"].navPath}/dashboard`,
    type: routes["dom-fleet"].type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#1973CB",
    dataTestIds: launchpad.tiles.fileManager,
    description: "descriptionFileManager",
    flag: "shell-enable-file-storage-1",
    icon: (size = "large") => (
      <Folder
        color="white"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.fileManager.icon)}
      />
    ),
    key: routes["file-manager"].key,
    productTypes: [Products.DSCC],
    resources: [
      "file-group.read",
      "file-protection-policy.read",
      "file-share.read",
      "file-share-metrics-history.read",
      "file-share-snapshot.read",
      "file-share-setting.read",
      "file-tenant.read",
      "file-user.read",
    ],
    tileType: TileType.SERVICE,
    title: "titleFileManager",
    to: routes["file-manager"].navPath,
    type: routes["file-manager"].type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#00775B",
    dataTestIds: launchpad.tiles.kubernetesService,
    description: "descriptionKubernetesService",
    flag: "ezmeral-kubernetes-service",
    icon: (size = "large") => (
      <Cluster
        color="white"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.kubernetesService.icon)}
      />
    ),
    key: routes.kubernetesService.key,
    productTypes: [Products.DSCC],
    resources: ["virtual-machine.read"],
    tileType: TileType.SERVICE,
    title: "titleKubernetesService",
    to: routes.kubernetesService.navPath,
    type: routes.kubernetesService.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#FC9935",
    dataTestIds: launchpad.tiles.objectStorage,
    description: "descriptionObjectStorage",
    flag: "shell-enable-objects-1",
    icon: (size = "large") => (
      <Resources
        color="white"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.objectStorage.icon)}
      />
    ),
    key: routes.objectStorage.key,
    productTypes: [Products.DSCC],
    resources: ["bucket.read"],
    tileType: TileType.SERVICE,
    title: "titleObjectStorage",
    to: `${routes.objectStorage.navPath}/dashboard`,
    type: routes.objectStorage.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#425563",
    dataTestIds: launchpad.tiles.pcbe,
    description: "descriptionPCBe",
    icon: (size = "large") => (
      <DriveCage
        color="white"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.pcbe.icon)}
      />
    ),
    key: routes.pcbe.key,
    productTypes: [Products.DSCC],
    tileType: TileType.SERVICE,
    title: "titlePCBe",
    to: routes.pcbe.navPath,
    type: routes.pcbe.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelLaunch",
    color: "#3F48CC",
    dataTestIds: launchpad.tiles.sfm,
    description: "descriptionSFM",
    flag: "shell-enable-sfm-1",
    icon: (size = "large") => (
      <Integration
        color="white"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.sfm.icon)}
      />
    ),
    key: routes.sfm.key,
    productTypes: [Products.DSCC],
    resources: [
      "controller.read",
      "datacenter.read",
      "host-initiator.read",
      "host-initiator-group.read",
      "port.read",
      "sfm-switch.read",
      "storage-fabric-report.read",
      "storage-fabric-topology.read",
      "storage-system.read",
    ],
    subscriptions: ["FABMGMT"],
    tileType: TileType.SERVICE,
    title: "titleSFM",
    to: `${routes.sfm.navPath}/dashboard`,
    type: routes.sfm.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.intentBasedProvisioning,
    description: "descriptionIntentBasedProvisioning",
    icon: (size = "xlarge") => (
      <Inherit
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.intentBasedProvisioning.icon)}
      />
    ),
    key: routes.intentBasedProvisioning.key,
    productTypes: [Products.DSCC, Products.ONPREM],
    resources: ["volume.create"],
    tileType: TileType.FEATURE,
    title: "titleIntentBasedProvisioning",
    to: routes.intentBasedProvisioning.navPath,
    type: routes.intentBasedProvisioning.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.announcements,
    description: "descriptionAnnouncements",
    flag: "shell-enable-announcements-1",
    icon: (size = "xlarge") => (
      <Announce
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.announcements.icon)}
      />
    ),
    key: routes["announcements"].key,
    productTypes: [Products.DSCC, Products.ONPREM, Products.ROP],
    resources: ["announcement.read"],
    tileType: TileType.FEATURE,
    title: "titleAnnouncements",
    to: routes["announcements"].navPath,
    type: routes["announcements"].type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.audit,
    description: "descriptionAudit",
    flag: "shell-enable-audit-1",
    icon: (size = "xlarge") => (
      <Compliance
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.audit.icon)}
      />
    ),
    key: routes.audit.key,
    productTypes: [Products.DSCC, Products.ONPREM, Products.ROP],
    resources: ["audit-event.read"],
    tileType: TileType.FEATURE,
    title: "titleAudit",
    to: routes.audit.navPath,
    type: routes.audit.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.dualAuth,
    description: "descriptionDualAuth",
    icon: (size = "xlarge") => (
      <Validate
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.dualAuth.icon)}
      />
    ),
    key: routes.dualAuth.key,
    productTypes: [Products.DSCC, Products.ONPREM],
    resources: ["customer-setting.read", "dual-auth-operation.read"],
    tileType: TileType.FEATURE,
    title: "titleDualAuth",
    to: routes.dualAuth.navPath,
    type: routes.dualAuth.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.email,
    description: "descriptionEmail",
    flag: "shell-enable-email-notifications-1",
    icon: (size = "xlarge") => (
      <MailOption
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.email.icon)}
      />
    ),
    key: routes.email.key,
    productTypes: [Products.DSCC, Products.ONPREM],
    tileType: TileType.FEATURE,
    title: "titleEmail",
    to: routes.email.navPath,
    type: routes.email.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.groups,
    description: "descriptionGroups",
    flag: "shell-enable-groups-1",
    icon: (size = "xlarge") => (
      <Group
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.groups.icon)}
      />
    ),
    key: routes.groups.key,
    productTypes: [Products.DSCC, Products.ONPREM],
    resources: ["group.read"],
    tileType: TileType.FEATURE,
    title: "titleGroups",
    to: routes.groups.navPath,
    type: routes.groups.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.secrets,
    description: "descriptionSecrets",
    flag: "shell-enable-secrets-1",
    icon: (size = "xlarge") => (
      <Secure
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.secrets.icon)}
      />
    ),
    key: routes.secrets.key,
    productTypes: [Products.DSCC, Products.ONPREM],
    resources: ["secret.read"],
    tileType: TileType.FEATURE,
    title: "titleSecrets",
    to: routes.secrets.navPath,
    type: routes.secrets.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.setup,
    description: "descriptionSetup",
    icon: (size = "large") => (
      <Install
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.setup.icon)}
      />
    ),
    key: routes.setup.key,
    productTypes: [Products.DSCC, Products.ONPREM],
    resources: ["blueprint.read", "device.read"],
    tileType: TileType.FEATURE,
    title: "titleSetup",
    to: routes.setup.navPath,
    type: routes.setup.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.shapeshifter,
    description: "descriptionShapeshifter",
    flag: "shell-enable-shapeshifter-1",
    icon: (size = "xlarge") => (
      <Resources
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.shapeshifter.icon)}
      />
    ),
    key: routes.shapeshifter.key,
    productTypes: [Products.DSCC, Products.ONPREM],
    tileType: TileType.FEATURE,
    title: "titleShapeshifter",
    to: routes.shapeshifter.navPath,
    type: routes.shapeshifter.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.tasks,
    description: "descriptionTasks",
    icon: (size = "xlarge") => (
      <Task
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.tasks.icon)}
      />
    ),
    key: routes.tasks.key,
    productTypes: [Products.DSCC, Products.ONPREM],
    resources: ["task.read"],
    tileType: TileType.FEATURE,
    title: "titleTasks",
    to: routes.tasks.navPath,
    type: routes.tasks.type,
  },
  {
    buttonLabel: "launchpadActionButtonLabelView",
    dataTestIds: launchpad.tiles.webhooks,
    description: "descriptionWebhooks",
    flag: "dscc-webhooks-service",
    icon: (size = "xlarge") => (
      <Technology
        color="text-strong"
        size={size}
        {...getTestIdAttribute(launchpad.tiles.webhooks.icon)}
      />
    ),
    key: routes.webhooks.key,
    productTypes: [Products.DSCC],
    resources: ["webhook.read"],
    tileType: TileType.FEATURE,
    title: "titleWebhooks",
    to: routes.webhooks.navPath,
    type: routes.webhooks.type,
  },
]

export const launchpadItems = items.filter((item) =>
  item.productTypes.includes(env.REACT_APP_PRODUCT)
)

import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { DSCCHeader, ROPHeader } from "./components"
import { AuthCallback, LoginRedirect, Logout, MockCCS } from "./oauth"
import {
  AuthenticatedDSCCRoutes,
  AuthenticatedROPRoutes,
  Login,
  NotFound,
  ShellRoute,
  Unauthorized,
} from "./routes"
import { routes } from "./shell-routes"
import { Products } from "./utils/constants"
import { env } from "./utils/env"

const getAppRoutes = (product) => {
  let appRoutes = []
  switch (product) {
    case Products.DSCC:
    case Products.ONPREM: {
      appRoutes = [
        {
          element: <LoginRedirect />,
          children: [
            {
              path: "/*",
              element: <AuthenticatedDSCCRoutes />,
            },
          ],
        },
        { path: "login", element: <Login /> },
        { path: "auth", element: <AuthCallback /> },
        { path: "logout", element: <Logout /> },
        { path: routes.unauthorized.path, element: <Unauthorized /> },
        {
          path: "ccs/*",
          element: <MockCCS enabled={process.env.NODE_ENV === "development"} />,
        },
        {
          path: "*",
          element: (
            <ShellRoute Header={DSCCHeader}>
              <NotFound />
            </ShellRoute>
          ),
        },
      ]
      break
    }
    case Products.ROP: {
      appRoutes = [
        {
          element: <LoginRedirect />,
          children: [
            {
              path: "/*",
              element: <AuthenticatedROPRoutes />,
            },
          ],
        },
        { path: "login", element: <Login /> },
        { path: "auth", element: <AuthCallback /> },
        { path: "logout", element: <Logout /> },
        {
          path: "ccs/*",
          element: <MockCCS enabled={process.env.NODE_ENV === "development"} />,
        },
        {
          path: "*",
          element: (
            <ShellRoute Header={ROPHeader}>
              <NotFound />
            </ShellRoute>
          ),
        },
      ]
      break
    }
    default: {
      break
    }
  }
  return appRoutes
}

const router = createBrowserRouter(getAppRoutes(env.REACT_APP_PRODUCT))

export default function AppRouter() {
  return <RouterProvider router={router} />
}

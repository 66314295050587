import { getTestIdAttribute, miniLaunchpad } from "../utils/test-ids"
import { Box, Text, Grid } from "grommet"
import { useTranslation } from "react-i18next"
import MiniLaunchPadItem from "./mini-launchpad-item"
import { Products, TileType } from "../utils/constants"
import { env } from "../utils/env"

const HorizontalPipe = () => {
  return (
    <Box border={{ side: "top", color: "#EEEEEE" }} fill="horizontal"></Box>
  )
}

const MiniLaunchPadContent = ({ contents, setOpen }) => {
  const { t } = useTranslation()

  const columns = { count: contents.length > 8 ? 3 : 2, size: "auto" }

  const contentsFeatures = contents.reduce(
    (acc, c) =>
      c.tileType === TileType.FEATURE
        ? [...acc, <MiniLaunchPadItem {...c} setOpen={setOpen} />]
        : acc,
    []
  )

  const contentsService = contents.reduce(
    (acc, c) =>
      c.tileType === TileType.SERVICE
        ? [...acc, <MiniLaunchPadItem {...c} setOpen={setOpen} />]
        : acc,
    []
  )

  return (
    <Box gap="medium">
      <Text weight={500} color="text-strong">
        {t(Products[env.REACT_APP_PRODUCT])}
      </Text>
      <Grid
        columns={columns}
        gap="small"
        {...getTestIdAttribute(miniLaunchpad.grid)}
      >
        {contentsService}
      </Grid>
      {contentsFeatures.length > 0 && contentsService.length > 0 && (
        <HorizontalPipe />
      )}
      <Grid
        columns={columns}
        gap="small"
        {...getTestIdAttribute(miniLaunchpad.grid)}
      >
        {contentsFeatures}
      </Grid>
    </Box>
  )
}

export default MiniLaunchPadContent

import { withLDProvider } from "launchdarkly-react-client-sdk"
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { App } from "./App"
import { flagManager } from "./flag-manager"
import { initShell } from "./init"
import "./i18n-config/i18n"
import { env } from "./utils/env"
import { LDConfig } from "./utils/launchdarkly"

async function bootstrapMirage() {
  try {
    const { createMockServerForCypress, createMockServerForDev } = await import(
      "./mirage/mock-server"
    )
    if (window.Cypress) {
      createMockServerForCypress()
    } else {
      createMockServerForDev()
    }
  } catch (err) {
    console.error(err)
  }
}

;(async () => {
  if (env.REACT_APP_PROXY_MIRAGE === "true") {
    await bootstrapMirage()
  }

  // initialize Launchdarkly client and fetch anonymous flags
  const fm = await flagManager.init()

  initShell({ flagClient: fm, window })

  // wrap App with Launchdarkly context provider, using our pre-initialized ldClient
  const LDWrappedApp = withLDProvider({ ...LDConfig, ldClient: fm.client })(App)

  const root = createRoot(document.getElementById("root"))
  root.render(
    <StrictMode>
      <LDWrappedApp />
    </StrictMode>
  )
})()

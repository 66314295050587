import { lazy, Suspense, useEffect, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { NavigationEventType } from "../events"
import { getPublication, getTopic } from "./utils"
import i18n from "../i18n-config/i18n"
import { env } from "../utils/env"

const HelpWidget = lazy(() => import("./help-widget"))

export default function Help() {
  const [currentHref, setCurrentHref] = useState(window.location.href)

  function updateHref(event) {
    setCurrentHref(event.detail.href)
  }

  useEffect(() => {
    window.addEventListener(NavigationEventType.SHELL, updateHref)
    return () => {
      window.removeEventListener(NavigationEventType.SHELL, updateHref)
    }
  }, [])

  return (
    <ErrorBoundary
      fallbackRender={() => null}
      onError={(error) => console.error(error)}
    >
      <Suspense fallback={null}>
        <HelpWidget
          hideTopicUrl={false}
          locale={i18n.language}
          proxy={env.REACT_APP_HELP_HOST}
          topicsId={getTopic(currentHref)}
          viewAllUrl={getPublication(currentHref)}
        />
      </Suspense>
    </ErrorBoundary>
  )
}

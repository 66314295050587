import i18n from "../i18n-config/i18n"
import { Products } from "../utils/constants"
import { env } from "../utils/env"

const baseTitle = `${i18n.t(Products[env.REACT_APP_PRODUCT])} - ${i18n.t(
  "platformBrandTitle"
)}`

const overrides = {
  aws: "AWS",
  ebs: "EBS",
  ec2: "EC2",
  vcenter: "vCenter",
  vmware: "VMware",
}

function formatWord(match, firstLetter, restOfWord) {
  return (
    overrides[match.toLowerCase()] ??
    `${firstLetter.toUpperCase()}${restOfWord ?? ""}`
  )
}

function titleFromPath(path) {
  return path
    .replace(/-+/g, " ")
    .replace(/\b([a-z])(\w+)?/gi, formatWord)
    .split("/")
    .reverse()
    .join(" - ")
}

/**
 * Build a document.title string from a given URL pathname.
 * @param {string} path - A URL pathname i.e. '/some/path' or window.location.pathname
 * @returns {string} A string describing the given path for usage in setting a page's document.title.
 */

export function buildTitle(path) {
  const pathTitle = !path || path === "/" ? "" : titleFromPath(path)

  return `${pathTitle}${baseTitle}`
}

import { Box, Grid } from "grommet"
import { useLayoutEffect } from "react"
import { Help } from "../contextual-help"
import FeedbackButton from "./feedback-button"
import TaskBanner from "./task-banner"
import { getTestIdAttribute, mainContent } from "../utils/test-ids"

export default function PageLayout({ children, footer, header }) {
  useLayoutEffect(() => {
    // re-insert global header/footer on component mount to ensure it exists and is visible.
    // By default, init is only called on initial page load but not if route changes and
    // PageLayout is unmounted/remounted which destroys the elements constructed during init.
    window.HPEHFWSLOADER?.insertHF(window.hfwsHeader, window.hfwsFooter)
    window.HPEHF?.forceReInit()

    return () => {
      // init bails early if this is true, so set false when unmounted
      // as the element no longer exists and needs to be initialized again on mount
      if (window.HPEHF) {
        window.HPEHF.hfInitialized = false
      }
    }
  }, [])

  return (
    <Grid columns={["auto", "min-content"]} height="100vh">
      <Box direction="column" flex="grow" overflow="auto">
        <div id="header" className="header" />
        {header && header}
        <Box
          as="main"
          background="#f7f7f7"
          flex={{ grow: 98 }}
          {...getTestIdAttribute(mainContent)}
        >
          {children}
        </Box>
        <FeedbackButton />
        <TaskBanner />
        {footer && footer}
      </Box>
      <Help />
    </Grid>
  )
}
